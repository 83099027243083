import styled from "styled-components";
import { ModalProps } from "../interfaces/modalInterface";

export const ModalComponent = ({ children, show }: ModalProps) => {
  const ModalDisplayBlock = styled.div`
    display: block;
  `;

  return show ? <ModalDisplayBlock>{children}</ModalDisplayBlock> : null;
};
