import React from "react";
import { BulletPointProps } from "../interfaces/bulletPointInterface";
import {
  bodyMedium,
  boldMedium,
  bodySmall,
} from "@bit/partnerhero.utils.typography";
import Icon from "@bit/partnerhero.icons.icon";
import styled from "styled-components";
import Grid from "@bit/partnerhero.layout.grid";
import { FontAwesomeSize } from "@bit/partnerhero.utils.enum";

export const MainBulletPoint = ({
  title,
  body,
  color,
  icon,
  aligment,
}: BulletPointProps) => {
  const Title = styled.p`
    ${bodyMedium}
    ${boldMedium}
    color: ${color};
    text-align: ${aligment};
    margin-bottom: 0.6rem;
  `;

  const Body = styled.p`
    ${bodySmall}
    color: ${color};
    text-align: ${aligment};
  `;

  return (
    <Grid
      item
      container
      direction="column"
      justifyContent="center"
      alignItems={aligment === "left" ? "flex-start" : "center"}
    >
      {icon && <Icon icon={icon} pro size={FontAwesomeSize.twoX} />}
      <Title>{title}</Title>
      <Body>{body}</Body>
    </Grid>
  );
};
