import Grid from "@bit/partnerhero.layout.grid";
import { Link } from "react-router-dom";
import styled from "styled-components";
import {
  BlockFooter,
  ContainerFooter,
  FooterPolicy,
  FooterTradeMark,
} from "../components/HomePageStyledComponets";

const TradeMarkLink = styled.a`
  cursor: pointer;
  text-decoration: none;
  color: inherit;
`;

export const MainFooter = () => {
  return (
    <ContainerFooter>
      <BlockFooter>
        {" "}
        <Grid container justify="space-between">
          <Grid item>
            <Link to="/privacy">
              <FooterPolicy>Terms &amp; Privacy Policy</FooterPolicy>
            </Link>
          </Grid>
          <Grid item>
            <FooterTradeMark>
              Vessel &copy; made with &hearts; at <TradeMarkLink href="http://partnerhero.com/">PartnerHero Inc</TradeMarkLink>, All Rights
              Reserved
            </FooterTradeMark>
          </Grid>
        </Grid>
      </BlockFooter>
    </ContainerFooter>
  );
};
