import {
  bodyMedium,
  bodySmall,
  boldMedium,
  boldSmall,
} from "@bit/partnerhero.utils.typography";
import Container from "@bit/partnerhero.layout.container";
import theme from "@bit/partnerhero.utils.theme";
import { Colors,FontAwesomeSize } from "@bit/partnerhero.utils.enum";
import styled from "styled-components";
import Grid from "@bit/partnerhero.layout.grid";
import { TitleA } from "../components/HomePageStyledComponets";
import { ModalComponent } from "../components/ModalComponent";
import { useState } from "react";
import Icon from "@bit/partnerhero.icons.icon";

export const PrivacyPolicy = () => {
  const [showTerms, setShowTerms] = useState(false);
  const [showPrivacy, setShowPrivacy] = useState(false);

  const handleTerms = () => {
    setShowTerms(!showTerms);
  };

  const handlePrivacy = () => {
    setShowPrivacy(!showPrivacy);
  };

  const Section = styled.div`
    color: ${theme.colors["primary-darkest"]};
    text-align: left;
    margin-bottom: 0.6rem;
  `;

  const SectionTitle = styled.p`
    ${bodyMedium}
    ${boldMedium}
    margin-bottom: 0;
    cursor: pointer;
  `;

  const Content = styled.div`
    margin: 0 auto;
  `;

  const SectionTheme = styled.p`
    ${boldSmall}
    margin-bottom: 0.2rem;
  `;

  const SectionParagraph = styled.p`
    ${bodySmall}
    margin-top: .5rem;
  `;

  const SectionParagraphLink = styled.a`
    ${boldSmall}
    margin-top: .5rem;
    text-decoration: none;
    color: ${theme.colors["primary-darkest"]};
  `;
  const SubCountSection = styled.p`
    ${bodySmall}
    margin: 0;
  `;

  const OrderedList = styled.ol`
    margin: 0 auto;
  `;

  const UnorderedList = styled.ul`
    margin: 0 auto;
  `;

  const OrderedSectionParagraph = styled.li`
    ${bodySmall}
    margin-top: .5rem;
  `;

  const OrderedSectionParagraphExample = styled.li`
    ${bodySmall}
    margin-top: .5rem;
    list-style-type: square;
  `;

  return (
    <Container backgroundColors={Colors.backgroundSecondaryLightest}>
      <Grid
        container
        justify="center"
        alignContent="center"
        alignItems="center"
      >
        <Grid item lg={8} md={8}>
          <TitleA>Terms of Service and Privacy Policy</TitleA>
        </Grid>
        <Grid item lg={9} md={9}>
          <Section>
            <SectionTitle onClick={handleTerms}>
              <Icon
                icon={showTerms ? "faChevronDown" : "faChevronRight"}
                pro
                size={FontAwesomeSize.xs}
              />{" "}
              Terms of Service
            </SectionTitle>
            <ModalComponent show={showTerms}>
              <Content>
                <SectionTheme>1. Terms</SectionTheme>
                <SectionParagraph>
                  By accessing the website at vessel.partnerhero.com, you are agreeing to be
                  bound by these terms of service, all applicable laws and
                  regulations, and agree that you are responsible for compliance
                  with any applicable local laws. If you do not agree with any
                  of these terms, you are prohibited from using or accessing
                  this site. The materials contained in this website are
                  protected by applicable copyright and trademark law.
                </SectionParagraph>
                <SectionTheme>2. User License</SectionTheme>
                <OrderedList>
                  <OrderedSectionParagraph>
                    Permission is granted to temporarily download one copy of
                    the materials (information or software) on PartnerHero Inc's
                    website for personal, non-commercial transitory viewing
                    only. This is the grant of a license, not a transfer of
                    title, and under this license you may not:
                    <SubCountSection>
                      a. modify or copy the materials;
                    </SubCountSection>
                    <SubCountSection>
                      b. use the materials for any commercial purpose, or for
                      any public display (commercial or non-commercial);
                    </SubCountSection>
                    <SubCountSection>
                      c. attempt to decompile or reverse engineer any software
                      contained on PartnerHero Inc's website;
                    </SubCountSection>
                    <SubCountSection>
                      d. remove any copyright or other proprietary notations
                      from the materials; or
                    </SubCountSection>
                    <SubCountSection>
                      e. transfer the materials to another person or "mirror"
                      the materials on any other server.
                    </SubCountSection>
                  </OrderedSectionParagraph>
                  <OrderedSectionParagraph>
                    This license shall automatically terminate if you violate
                    any of these restrictions and may be terminated by
                    PartnerHero Inc at any time. Upon terminating your viewing
                    of these materials or upon the termination of this license,
                    you must destroy any downloaded materials in your possession
                    whether in electronic or printed format.
                  </OrderedSectionParagraph>
                </OrderedList>
                <SectionTheme>3. Disclaimer</SectionTheme>
                <OrderedList>
                  <OrderedSectionParagraph>
                    The materials on PartnerHero Inc’s website are provided on
                    an ‘as is’ basis. PartnerHero Inc makes no warranties,
                    expressed or implied, and hereby disclaims and negates all
                    other warranties including, without limitation, implied
                    warranties or conditions of merchantability, fitness for a
                    particular purpose, or non-infringement of intellectual
                    property or other violation of rights.
                  </OrderedSectionParagraph>
                  <OrderedSectionParagraph>
                    Further, PartnerHero Inc does not warrant or make any
                    representations concerning the accuracy, likely results, or
                    reliability of the use of the materials on its website or
                    otherwise relating to such materials or on any sites linked
                    to this site.
                  </OrderedSectionParagraph>
                </OrderedList>
                <SectionTheme>4. Limitations</SectionTheme>
                <SectionParagraph>
                  In no event shall PartnerHero Inc or its suppliers be liable
                  for any damages (including, without limitation, damages for
                  loss of data or profit, or due to business interruption)
                  arising out of the use or inability to use the materials on
                  PartnerHero Inc’s website, even if PartnerHero Inc or a
                  PartnerHero Inc authorized representative has been notified
                  orally or in writing of the possibility of such damage.
                  Because some jurisdictions do not allow limitations on implied
                  warranties, or limitations of liability for consequential or
                  incidental damages, these limitations may not apply to you.
                </SectionParagraph>
                <SectionTheme>5. Accuracy of materials</SectionTheme>
                <SectionParagraph>
                  The materials appearing on PartnerHero Inc's website could
                  include technical, typographical, or photographic errors.
                  PartnerHero Inc does not warrant that any of the materials on
                  its website are accurate, complete or current. PartnerHero Inc
                  may make changes to the materials contained on its website at
                  any time without notice. However PartnerHero Inc does not make
                  any commitment to update the materials.
                </SectionParagraph>
                <SectionTheme>6. Links</SectionTheme>
                <SectionParagraph>
                  PartnerHero Inc has not reviewed all of the sites linked to
                  its website and is not responsible for the contents of any
                  such linked site. The inclusion of any link does not imply
                  endorsement by PartnerHero Inc of the site. Use of any such
                  linked website is at the user's own risk.
                </SectionParagraph>
                <SectionTheme>7. Modifications</SectionTheme>
                <SectionParagraph>
                  PartnerHero Inc may revise these terms of service for its
                  website at any time without notice. By using this website you
                  are agreeing to be bound by the then current version of these
                  terms of service.
                </SectionParagraph>
                <SectionTheme>8. Governing Law</SectionTheme>
                <SectionParagraph>
                  These terms and conditions are governed by and construed in
                  accordance with the laws of California and you irrevocably
                  submit to the exclusive jurisdiction of the courts in that
                  State or location.
                </SectionParagraph>
              </Content>
            </ModalComponent>
            <SectionTitle onClick={handlePrivacy}>
              <Icon
                icon={showPrivacy ? "faChevronDown" : "faChevronRight"}
                pro
                size={FontAwesomeSize.xs}
              />{" "}
              Privacy Policy
            </SectionTitle>
            <ModalComponent show={showPrivacy}>
              <Content>
                <SectionParagraph>
                  At PartnerHero, we value your privacy as much as we value our
                  own. It's our policy to respect your privacy when it comes to
                  any and all information we might collect while operating our
                  website, products, or services. Accordingly, we have developed
                  this privacy policy in order for you to understand how we
                  collect, use, communicate, disclose and otherwise make use of
                  personal information. We have outlined our privacy policy
                  below.
                </SectionParagraph>
                <SectionParagraph>
                  We commit to informing you of the data we're collecting and
                  how we intend to use it.
                </SectionParagraph>
                <SectionParagraph>
                  We commit to collecting and using personal information solely
                  for the purpose of providing services as advertised, or in
                  support of providing services as advertised.
                </SectionParagraph>
                <SectionParagraph>
                  We commit to ensuring information collected is relevant to the
                  purposes for which it is to be used. We commit to protecting
                  your personal information by using reasonable security
                  safeguards against loss, theft, unauthorized access,
                  disclosure, copying, use, or modification.
                </SectionParagraph>
                <SectionParagraph>
                  We commit to making our policies and practices relating to the
                  management of personal information readily available and easy
                  to understand.
                </SectionParagraph>
                <SectionParagraph>
                  We commit to only retaining personal information for as long
                  as necessary for the fulfillment of these stated purposes.
                </SectionParagraph>
                <SectionParagraph>
                  We will never sell, share, or trade your data with any 3rd
                  party.
                </SectionParagraph>
                <SectionParagraph>
                  We will notify users within 24 hours of any suspected data
                  loss or breach.
                </SectionParagraph>
                <SectionParagraph>
                  We will comply with legal authorities as required by law.
                </SectionParagraph>
                <SectionParagraph>
                  The use of information received from Google APIs will adhere
                  to the{" "}
                  <SectionParagraphLink href="https://developer.chrome.com/docs/webstore/program_policies/">
                    Chrome Web Store User Data Policy
                  </SectionParagraphLink>
                  , including the{" "}
                  <SectionParagraphLink href="https://developer.chrome.com/docs/webstore/program_policies/#limited_use">
                    Limited Use
                  </SectionParagraphLink>{" "}
                  requirements.
                </SectionParagraph>
                <SectionTheme>Overview</SectionTheme>
                <SectionParagraph>
                  Vessel is a Chrome extension that listens for DOM interactions
                  related to customer service work so people can understand
                  their productivity throughout the day. A subset of those
                  interactions are captured and sent as event logs to a
                  database, and event logs can then be abstracted into
                  high-level metrics.{" "}
                </SectionParagraph>
                <UnorderedList>
                  <OrderedSectionParagraph>
                    Vessel is a Chrome extension that listens for DOM
                    interactions related to customer service work so people can
                    understand their productivity throughout the day. A subset
                    of those interactions are captured and sent as event logs to
                    a database, and event logs can then be abstracted into
                    high-level metrics.
                    <UnorderedList>
                      <OrderedSectionParagraph>
                        Scenario: You’re a manager, and you want to listen to
                        how many times someone clicked the “Approve” button for
                        purchase orders, and your agents also use a keyboard
                        shortcut to resolve cases. Through Vessel’s settings,
                        you can enable logs to be sent every time that button is
                        clicked, and every time that keyboard shortcut is used
                        to resolve cases.
                      </OrderedSectionParagraph>
                      <OrderedSectionParagraphExample>
                        Example: agent clicked 15 times on the “Approve” button
                        for purchase orders, and resolve 5 cases today by
                        pressing “Ctrl + Enter”. They also reloaded the page 3
                        times and closed all the tabs at the end of the day.
                      </OrderedSectionParagraphExample>
                    </UnorderedList>
                  </OrderedSectionParagraph>
                  <OrderedSectionParagraph>
                    Compound - Timestamps: time on page/URL and time on modal.
                    Vessel captures a timestamp in every log, and the logs can
                    be used to determine how much time was spent in a given CRM.
                    <UnorderedList>
                      <OrderedSectionParagraph>
                        Scenario: You’re a manager, and you want Vessel to
                        capture a log when the “Sign in” and “Sign out” buttons
                        are clicked by an agent.
                      </OrderedSectionParagraph>
                      <OrderedSectionParagraphExample>
                        Example: agent signed in to Zendesk at 8:13 am today,
                        and signed out at 12 pm for lunch. They signed in again
                        at 1 pm and signed out for the day at 5 pm.
                      </OrderedSectionParagraphExample>
                    </UnorderedList>
                  </OrderedSectionParagraph>
                </UnorderedList>
                <SectionTheme>DATA USAGE</SectionTheme>
                <SectionTheme>User Accounts</SectionTheme>
                <SectionParagraph>
                  Information we collect from each user's Google account:
                </SectionParagraph>
                <UnorderedList>
                  <OrderedSectionParagraph>
                    Email Address
                  </OrderedSectionParagraph>
                </UnorderedList>
                <SectionTheme>Tabs:</SectionTheme>
                <UnorderedList>
                  <OrderedSectionParagraph>
                    Tab ID
                    <UnorderedList>
                      <OrderedSectionParagraph>
                        Used for tab-based time in tool calculations
                      </OrderedSectionParagraph>
                    </UnorderedList>
                  </OrderedSectionParagraph>
                  <OrderedSectionParagraph>
                    Visited URL for approved domains
                    <UnorderedList>
                      <OrderedSectionParagraph>
                        Work-related domains defined by managers. Vessel works
                        exclusively on these domains.
                      </OrderedSectionParagraph>
                    </UnorderedList>
                  </OrderedSectionParagraph>
                  <OrderedSectionParagraph>
                    DOM selectors
                    <UnorderedList>
                      <OrderedSectionParagraph>
                        Used to define which clicked buttons Vessel will listen
                        to
                      </OrderedSectionParagraph>
                    </UnorderedList>
                  </OrderedSectionParagraph>
                  <OrderedSectionParagraph>
                    Timestamps
                    <UnorderedList>
                      <OrderedSectionParagraph>
                        Used for time in tool calculations
                      </OrderedSectionParagraph>
                    </UnorderedList>
                  </OrderedSectionParagraph>
                </UnorderedList>
                <SectionTheme>Local Storage</SectionTheme>
                <UnorderedList>
                  <OrderedSectionParagraph>
                    Tab statuses
                    <UnorderedList>
                      <OrderedSectionParagraph>
                        Example: refreshed, in focus, closed tab. Used for time
                        in tool calculations.
                      </OrderedSectionParagraph>
                    </UnorderedList>
                  </OrderedSectionParagraph>
                  <OrderedSectionParagraph>
                    Keyboard commands while on the page (if enabled in settings)
                    <UnorderedList>
                      <OrderedSectionParagraph>
                        Example: if you use “Ctrl + Enter” to “Resolve” a
                        conversation, a new “Resolved” log will be sent to our
                        database every time you use that keyboard shortcut.
                      </OrderedSectionParagraph>
                    </UnorderedList>
                  </OrderedSectionParagraph>
                </UnorderedList>
                <SectionParagraph>
                  We are committed to conducting our business in accordance with
                  these principles in order to ensure that the confidentiality
                  of personal information is protected and maintained.
                  PartnerHero Inc may change this privacy policy from time to
                  time at PartnerHero Inc's sole discretion.
                </SectionParagraph>
                <SectionParagraph>
                  If you have any questions about how Vessel handles your
                  personal data, please contact{" "}
                  <SectionParagraphLink href="mailto:dataprivacy@partnerhero.com">
                    dataprivacy@partnerhero.com
                  </SectionParagraphLink>{" "}
                  and we will be happy to help you.
                </SectionParagraph>
              </Content>
            </ModalComponent>
          </Section>
        </Grid>
      </Grid>
    </Container>
  );
};
