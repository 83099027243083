import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import { GlobalStyle, MarginNavbar, SmoothScroll } from "./components/HomePageStyledComponets";
import { MainNavBar } from "./components/MainNavBar";
import { MainFooter } from "./components/MainFooter";
import { HomePage } from "./pages/HomePage";
import { PrivacyPolicy } from "./pages/PrivacyPolicy";
import {ScrollToTop} from "./components/ScrollToTop"

const Routes = () => (
	<Router>
		<ScrollToTop />
		<GlobalStyle />
      	<SmoothScroll>
      		<MainNavBar />
			<MarginNavbar>
			<Switch>
				<Route
					exact
					path="/"
					component={HomePage}
				/>
				<Route
					exact
					path="/privacy"
					component={PrivacyPolicy}
				/>
			</Switch>
			</MarginNavbar>
			<MainFooter />
		</SmoothScroll>
	</Router>
);

export default Routes;