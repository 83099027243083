import { bodySmall } from "@bit/partnerhero.utils.typography";
import styled from "styled-components";
import { TestimonialProps } from "../interfaces/testimonialInterface";
import theme from "@bit/partnerhero.utils.theme";
import Container from "@bit/partnerhero.navigation.app_bar/dist/components/container";

export const MainTestimonial = ({ content, author }: TestimonialProps) => {
  const BodyText = styled.p`
    ${bodySmall}
    color: ${theme.colors.black};
  `;

  const AuthorText = styled.p`
    ${bodySmall}
    color: ${theme.colors["dark-grey"]};
    margin-top: 5px;
  `;

  return (
    <Container>
      <div
        style={{
          backgroundColor: theme.colors.grey,
          padding: "20px",
          color: theme.colors["primary-darkest"],
          borderRadius: "20px",
        }}
      >
        <BodyText>{content}</BodyText>
      </div>

      <AuthorText>{author}</AuthorText>
    </Container>
  );
};
