import styled, { createGlobalStyle } from "styled-components";
import {
  header4Style,
  header5Style,
  bodyMedium,
  bodySmall,
} from "@bit/partnerhero.utils.typography";
import theme from "@bit/partnerhero.utils.theme";

export const TitleA = styled.h4`
  ${header4Style}
  color: ${theme.colors["primary-darkest"]};
  text-align: center;
`;

export const TitleB = styled.h5`
  ${header5Style}
  color: ${theme.colors["primary-darkest"]};
  text-align: left;
`;

export const BodyA = styled.p`
  ${bodyMedium}
  color: ${theme.colors["primary-darkest"]};
  text-align: center;
`;

export const BodyB = styled.p`
  ${bodyMedium}
  color: ${theme.colors["primary-darkest"]};
  text-align: left;
`;

export const FooterPolicy = styled.p`
  ${bodySmall}
  color: ${theme.colors.white};
`;

export const FooterTradeMark = styled.p`
  ${bodySmall}
  color: ${theme.colors.grey};
`;

export const Block = styled.div`
  margin: 8rem 0;
  display: flex;
  align-items: center;
`;

export const Block2 = styled.div`
  margin: 7rem 0;
  display: flex;
  align-items: center;
`;

export const ContainerFooter = styled.footer`
  background-image: url(${process.env.PUBLIC_URL + "/assets/Vector.svg"});
  width: 100%;
  height: 223px;
  align-items: center;
  text-align: center;
  position: sticky;
  top: 100%;
  background-repeat: no-repeat;
  background-size: cover;
`;

export const BlockFooter = styled.div`
  display: flex;
  align-items: center;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  height: 30%;
  margin: auto;
  padding: 20px;
`;

export const GlobalStyle = createGlobalStyle`
    html{
      scroll-behavior: smooth;
    }
    a{
      text-decoration: none;
    }
  `;

export const SmoothScroll = styled.div`
  min-height: 100vh;
  background-color: ${theme.colors["background-secondary-lightest"]};
`;

export const MarginNavbar = styled.div`
  margin-top: 5rem;
`;
