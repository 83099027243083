import Grid from "@bit/partnerhero.layout.grid";
import Container from "@bit/partnerhero.layout.container";
import { MainBulletPoint } from "../components/MainBulletPoint";
import theme from "@bit/partnerhero.utils.theme";
import { Colors } from "@bit/partnerhero.utils.enum";
import { MainTestimonial } from "../components/MainTestimonial";
import {
  Block2,
  Block,
  TitleA,
  TitleB,
  BodyA,
  BodyB,
} from "../components/HomePageStyledComponets";

export const HomePage = () => {
  return (
    <>
      <Container backgroundColors={Colors.backgroundSecondaryLightest}>
        <Block>
          <Grid
            container
            justify="center"
            alignContent="center"
            alignItems="center"
          >
            <Grid item lg={8} md={8}>
              <TitleA>Global productivity data within your reach</TitleA>
              <BodyA>
                Vessel works in the background while your agents are connected
                to any website, without interrupting their workflow.
              </BodyA>
            </Grid>
          </Grid>
        </Block>
      </Container>
      <Container>
        <Block id="features">
          <Grid
            container
            justifyContent="space-between"
            alignContent="center"
            alignItems="flex-start"
            spacing={4}
            style={{ paddingLeft: "11rem", paddingRight: "11rem" }}
          >
            <Grid item lg={4} md={4} style={{ padding: "unset" }}>
              <MainBulletPoint
                title="Ditch GSheets in 5 minutes"
                body="Your team can focus on the work that matters instead of using Forms and tally marks to log productivity."
                color={theme.colors["primary-darkest"]}
                icon="faMeteor"
                aligment="center"
              />
            </Grid>
            <Grid item lg={4} md={4} style={{ padding: "unset" }}>
              <MainBulletPoint
                title="Improve staffing &amp; team allocation"
                body="Use Vessel insights to forecast and manage your team’s utilization to ensure they’re in the right place at the right time."
                color={theme.colors["primary-darkest"]}
                icon="faPeopleArrows"
                aligment="center"
              />
            </Grid>
            <Grid item lg={4} md={4} style={{ padding: "unset" }}>
              <MainBulletPoint
                title="Empower your agents"
                body="Universal productivity reporting shows daily and hourly progress across tools."
                color={theme.colors["primary-darkest"]}
                icon="faSmile"
                aligment="center"
              />
            </Grid>
          </Grid>
        </Block>
      </Container>
      <Container backgroundColors={Colors.backgroundSecondaryLightest}>
        <Block2 id="about-us">
          <Grid
            container
            alignItems="center"
            direction="column"
            style={{ paddingRight: "11rem", paddingLeft: "11rem" }}
          >
            <Grid item xs={12}>
              <TitleB>
                Work happens anywhere, but managers can’t be everywhere.
              </TitleB>
              <BodyB>
                Vessel is a Chrome Extension built by PartnerHero that provides
                a seamless way to generate time in tool and productivity data
                for your team across any systems you use. It listens to specific
                interactions inside work tools, and team managers and leads can
                use these interactions to have close to real-time insights on
                how their agents’ work is adding up throughout the day.
              </BodyB>
            </Grid>
            <Grid
              item
              container
              xs={12}
              justifyContent="space-between"
              direction="row"
              spacing={3}
            >
              <Grid item lg={4} md={4} style={{ padding: "unset" }}>
                <MainBulletPoint
                  title="Real-time"
                  body="Measure discrete actions (chats, solves, merges) as well as engagement (start-to-end time ranges)."
                  color={theme.colors["primary-darkest"]}
                  aligment="left"
                />
              </Grid>
              <Grid item lg={4} md={4} style={{ padding: "unset" }}>
                <MainBulletPoint
                  title="Non-intrusive"
                  body="No API keys or Admin credentials needed."
                  color={theme.colors["primary-darkest"]}
                  aligment="left"
                />
              </Grid>
              <Grid item lg={4} md={4} style={{ padding: "unset" }}>
                <MainBulletPoint
                  title="Adaptable"
                  body="Works with any third-party tool."
                  color={theme.colors["primary-darkest"]}
                  aligment="left"
                />
              </Grid>
            </Grid>
          </Grid>
        </Block2>
      </Container>
      <Container>
        <Block2>
          <Grid
            container
            justifyContent="space-between"
            spacing={4}
            style={{
              padding: "15rem 11rem 0 11rem",
            }}
          >
            <Grid item xs={5} style={{ transform: "translateY(-50%)" }}>
              <MainTestimonial
                content="Vessel helped my team verify best practices for our workflows, which lead to streamlined processes and productivity. We are able to tell in real-time how our goals are being met."
                author="Stanley Franco,  Program Manager at PartnerHero"
              />
            </Grid>
            <Grid item xs={5}>
              <MainTestimonial
                content="Vessel has provided my team great insights into associate sales information. Specifically: Total sales by agent, insights regarding orders driven by catalogs, and utilization of order numbers to find top-selling SKUs via phone orders."
                author="Buzz Valutis, Sr. Program Manager at PartnerHero"
              />
            </Grid>
          </Grid>
        </Block2>
      </Container>
    </>
  );
};
